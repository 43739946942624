import React, { useContext, useState, useEffect } from "react";
import { fbApp } from "../firebase";
import {
  getAuth,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { getFirestore, doc, setDoc, getDoc } from "firebase/firestore";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export default function AuthProvider({ children }) {
  const auth = getAuth(fbApp);
  const db = getFirestore(fbApp);
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);

  async function logOut() {
    return await auth.signOut().then(() => {
      window.location.reload();
    });
  }

  function logIn(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  function forgotPassword(email) {
    return sendPasswordResetEmail(email);
  }

  async function signUp(email, password, data) {
    return createUserWithEmailAndPassword(auth, email, password).then(
      (userCred) => {
        // You can capture other details when the user signs up, like name and surname and pass them to the sign up function,
        // by adding them to the object passed below as the second argument. Remember to add them as parameters to the signUp function itself
        createUserRecord(userCred.user.uid, { email, ...data });
      }
    );
  }

  function createUserRecord(uid, data) {
    return setDoc(doc(db, "users", uid), {
      ...data,
    });
  }

  async function getUserRecord(uid) {
    const userSnap = await getDoc(doc(db, "users", uid));
    return userSnap.data();
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userDoc = await getUserRecord(user.uid);
        setCurrentUser({ ...user, ...userDoc });
      }
      setLoading(false);
    });
    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    signUp,
    logOut,
    logIn,
    forgotPassword,
  };
  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
