import React from 'react'
import { Button, Divider, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { AccountBox, ExitToApp, History, Home, LocalShipping, People } from '@mui/icons-material';
import { Link } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext';

export default function AdminMenu({ classes }) {
    const { logOut } = useAuth()
    return (
        <div>
            <List>
                <Link to="/">
                    <ListItem>
                        <ListItemIcon className={classes.icons}><Home /></ListItemIcon>
                        <ListItemText className={classes.menuText}>Home</ListItemText>
                    </ListItem>
                </Link>
            </List>
            <Divider />
            <Link to="/account">
                <ListItem>
                    <ListItemIcon className={classes.icons}><AccountBox /></ListItemIcon>
                    <ListItemText className={classes.menuText}>Your Account</ListItemText>
                </ListItem>
            </Link>
            <ListItem>
                <Button
                    startIcon={<ExitToApp />}
                    onClick={() => logOut()}
                    color="primary"
                    variant="contained"
                >
                    Log Out
                </Button>
            </ListItem>
            
        </div>
    )
}
