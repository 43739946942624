import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const config = {
  // Paste your Firebase config here
  apiKey: "AIzaSyAzEzCpNfIUa5aDjW850wxMCYHmsr5_15c",
  authDomain: "sim-sentry.firebaseapp.com",
  projectId: "sim-sentry",
  storageBucket: "sim-sentry.appspot.com",
  messagingSenderId: "888898113477",
  appId: "1:888898113477:web:f41aaa0de469ab354ac5ac",
  measurementId: "G-7GYY496GKC",
};

const app = initializeApp(config);
const analytics = getAnalytics(app);
export const fbApp = app;
