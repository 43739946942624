import React from "react";
import { Routes, Route } from "react-router-dom";
import AdminHome from "../components/admin/AdminHome";

export default function AdminRoutes() {
  return (
    <Routes>
      <Route exact path="/" element={<AdminHome />} />
    </Routes>
  );
}
