import { createTheme, ThemeProvider, StyledEngineProvider, adaptV4Theme } from '@mui/material';
import React from 'react'
import { useAuth } from '../contexts/AuthContext'
import { DatabaseProvider } from '../contexts/DatabaseContext'
import PublicRoutes from '../routes/PublicRoutes'
import AdminPage from './admin/AdminPage'
import CustomerPage from './customer/CustomerPage'


// Set your theme colors and styles below. Details on Theming here: https://material-ui.com/customization/theming/#theming
const theme = createTheme(adaptV4Theme({
    palette: {
        primary: {
            main: '#5ca0a2',
            contrastText: '#fff'
        },
        mainMenuText: '#fff'
    }
}))

export default function Wrapper() {
    const { currentUser } = useAuth()
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <DatabaseProvider>
                    {
                        currentUser
                        ?
                        currentUser.admin ? <AdminPage /> : <CustomerPage /> 
                        :
                        <PublicRoutes />
                    }
                </DatabaseProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}
