import React, { useContext, useState, useEffect } from "react";
import { fbApp } from "../firebase";
import { getFirestore, collection } from "firebase/firestore";

const DatabaseContext = React.createContext();

export function useDb() {
  return useContext(DatabaseContext);
}

export function DatabaseProvider({ children }) {
  const db = getFirestore(fbApp);

  // Collection References
  const usersCollection = collection(db, "users");

  const value = {};

  return (
    <DatabaseContext.Provider value={value}>
      {children}
    </DatabaseContext.Provider>
  );
}
